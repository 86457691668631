<template>
  <div class="admin-view-promotions">
    <advanced-data-table
      action="promotions/get"
      :headers="headers"
      ref="table"
      :filters.sync="filters"
    >
      <template #cell(title)="{item}">
        {{ item.book_title }}
      </template>

      <template #cell(client)="{item}">
        {{ item.user && item.user.name }}
      </template>

      <template #cell(price)="{item}">
        {{ getBaseCurrencyPrice(item) }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <!-- Actions -->
      <template #actions="{item}">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          :loading="deleting === item.id"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-form-select
            :options="addAllOption(promotionStatuses)"
            v-model="filters.status"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>

        <!-- Book filter -->
        <adt-filter :label="$t('book')">
          <item-search
            action="books/search"
            label="title"
            trackBy="id"
            v-model="filters.book_id"
          />
        </adt-filter>
      </template>
    </advanced-data-table>

    <!-- Promotion edit modal -->
    <ebp-modal
      :open.sync="showEdit"
      :width="isSmallScreen ? 'auto' : '30%'"
      :tabs="editTabs"
    >
      <template #error><error :err.sync="error"/></template>
      <template #quick-edit>
        <form-generator
          v-if="editedItem"
          :elements="elements"
          :data="editedItem"
          :handleUpdate="({ key }, v) => (editedItem[key] = v)"
        />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import { find } from "lodash-es";
import { mapState } from "vuex";
import promotionStatuses from "@/fixtures/promotion-statuses";
import ItemSearch from "@/components/core/item-search";
import AdtFilter from "@/components/core/adt-filter";
import FormGenerator from "@/components/form-generator";

export default {
  name: "admin-view-promotions",
  components: {
    AdvancedDataTable,
    ItemSearch,
    FormGenerator,
    AdtFilter
  },
  data() {
    return {
      saving: false,
      showEdit: false,
      editedItem: null,
      error: null,
      editTabs: ["quick-edit"],
      promotionStatuses: promotionStatuses.map(p => ({
        text: this.$t(p),
        value: p
      })),
      filters: {
        status: null,
        user_id: null,
        book_id: null,
        sort_by: "created_at",
        sort_order: true
      },
      deleting: false,
      promotions: [],
      page: 1,
      limit: 10,
      headers: [
        {
          key: "title",
          label: "book"
        },
        "client",
        "price",
        "status",
        "actions"
      ].map(i => ({
        ...(typeof i === "object" ? i : { key: i, label: i }),
        sortable: false
      })),
      currencyByCode: {}
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getCurrencies");
  },
  computed: {
    ...mapState("ancillaries", ["currencies"]),
    elements() {
      return [
        {
          key: "status",
          label: "status",
          type: "select",
          attrs: {
            options: this.promotionStatuses
          }
        },
        {
          type: "button-group",
          className: "float-right",
          elements: [
            {
              text: "save",
              type: "button",
              className: "px-5 float-right mt-3",
              attrs: {
                loading: this.saving
              },
              events: {
                click: this.save
              }
            },
            {
              text: "complete-edit",
              type: "button",
              className: "px-5 mt-3 btn-dark",
              events: {
                click: () => {
                  this.showEdit = false;
                  this.$nextTick(() => {
                    this.$router.push({
                      name: "AdminEditPromotion",
                      params: { id: this.editedItem.id }
                    });
                  });
                }
              }
            }
          ]
        }
      ];
    }
  },
  methods: {
    async save() {
      this.saving = true;

      try {
        await this.$store.dispatch("promotions/update", this.editedItem);
        this.showEdit = false;
      } catch (err) {
        console.error(err);
      }

      this.saving = false;
    },
    edit(promotion) {
      this.editedItem = promotion;
      this.showEdit = true;
    },
    getBaseCurrencyPrice(promotion) {
      const baseCurrency =
        this.currencyByCode[promotion.base_currency] ||
        find(this.currencies, o => o.code === promotion.base_currency);
      if (!baseCurrency) return this.$t("n-a");

      // For faster future runs
      this.currencyByCode[baseCurrency.code] = baseCurrency;

      const basePrice = find(
        promotion.prices,
        o => o.currency_id === baseCurrency.id
      );
      if (!basePrice) return this.$t("n-a");

      return basePrice.price + " " + baseCurrency.code;
    },
    splitKeywords(keywords) {
      return keywords.split(",");
    },
    async remove({ book_title, id }) {
      if (confirm(this.$t("remove-promotion", { title: book_title }))) {
        this.deleting = id;

        try {
          await this.$store.dispatch("promotions/delete", id);
          this.$refs.table.change("id", id);
        } catch (err) {
          console.error(err);
        }

        this.deleting = false;
      }
    }
  }
};
</script>

<style></style>
